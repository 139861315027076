import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

export default function Loader({ ...rest }): JSX.Element {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            py={3}
            {...rest}
        >
            <CircularProgress />
        </Box>
    )
}
