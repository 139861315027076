import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Button, Typography } from '@mui/material'
import Logo from '../Logo'
import { getAdminFromLocalStorage, logoutAdminUser } from '../../Services'

const HeaderBox = styled(Box)(({ theme }) => ({
    boxShadow: theme.shadows[1],
    backgroundColor: '#F3F4F6',
    zIndex: 2,
    top: 0,
    position: 'sticky',
    padding: '1rem 0',
}))

const Navbar = (): ReactNode => {
    const [admin] = useState(getAdminFromLocalStorage())

    return (
        <HeaderBox>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexDirection={{ xs: 'column', sm: 'row' }}
                padding="0 1rem"
            >
                <Box width={250}>
                    <Logo />
                </Box>
                <Box sx={{ mt: { xs: 3, sm: 0 } }}>
                    <Typography variant="h6" component="span">
                        {admin?.user?.name}
                    </Typography>
                    <Typography variant="h6" component="span" sx={{ ml: 1.5 }}>
                        {' | '}
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ ml: 2 }}
                        onClick={logoutAdminUser}
                    >
                        Logout
                    </Button>
                </Box>
            </Box>
        </HeaderBox>
    )
}

export default Navbar
