import React from 'react'
import {
    Card,
    CardActions,
    CardHeader,
    CardMedia,
    Box,
    IconButton,
} from '@mui/material'
import { Delete, Edit, Facebook, LinkedIn, Twitter } from '@mui/icons-material'
import { SPONSOR_TYPES } from '../Modal/SponsorModal'

interface ISponsorCardProps {
    sponsor: ISponsor
    sponsorType: string
    handleEditButtonClick?: (id: string, sponsorType: string) => void
    openDeleteModal?: (id: string) => void
}

const SponsorCard = ({
    sponsor,
    handleEditButtonClick,
    openDeleteModal,
}: ISponsorCardProps): ReactNode => {
    const {
        _id: id,
        companyName,
        contactName,
        email,
        sponsorType,
        primaryPhone,
        corporatePhone,
        websiteUrl,
        logoUrl,
        city,
        state,
        facebookLink,
        twitterLink,
        linkedinLink,
    } = sponsor

    return (
        <Card
            sx={{
                maxWidth: '100%',
                bgcolor: '#eee',
                mb: 2,
            }}
        >
            <CardHeader
                action={
                    <>
                        <IconButton
                            aria-label="Edit Sponsor"
                            onClick={() =>
                                handleEditButtonClick(id, sponsorType)
                            }
                        >
                            <Edit />
                        </IconButton>

                        <IconButton
                            aria-label="Delete Sponsor"
                            onClick={() => openDeleteModal(id)}
                        >
                            <Delete />
                        </IconButton>
                    </>
                }
                title={companyName}
                subheader={city ? `${city}, ${state}` : state}
                sx={{
                    padding: '1rem',
                    wordBreak: 'break-word',
                }}
            />
            <CardMedia
                component="img"
                height="150"
                image={logoUrl}
                alt="Sponsor Logo"
                sx={{
                    objectFit: 'cover',
                    objectPosition: 'center',
                }}
            />

            <CardActions disableSpacing sx={{ px: 2 }}>
                <Box sx={{ ml: 'auto' }}>
                    <a href={facebookLink}>
                        <IconButton aria-label="external link">
                            <Facebook />
                        </IconButton>
                    </a>
                    <a href={twitterLink}>
                        <IconButton aria-label="external link">
                            <Twitter />
                        </IconButton>
                    </a>
                    <a href={linkedinLink}>
                        <IconButton aria-label="external link">
                            <LinkedIn />
                        </IconButton>
                    </a>
                </Box>
            </CardActions>
        </Card>
    )
}

export default SponsorCard
