import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { getAdminFromLocalStorage } from '../Services'

export const AdminProtectedRoute = ({ children }): JSX.Element => {
    const [admin] = useState(getAdminFromLocalStorage())

    return (
        <>
            {!admin.user || !admin.token ? (
                <Navigate to="/admin-login" />
            ) : (
                <>{children}</>
            )}
        </>
    )
}
