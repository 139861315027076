import { AxiosResponse } from 'axios'
import { useEffect, useState, useContext } from 'react'
import {
    Typography,
    Card,
    Grid,
    CardContent,
    Button,
    Divider,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { getAllSponsors } from '../../Services/sponsors'
import { MessageContext, IMessageContext } from '../../context/MessageContext'
import EmptyData from '../../components/EmptyData'

export default function Sponsers(): JSX.Element {
    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const [loading, setLoading] = useState<boolean>(true)
    const [sponsors, setSponsors] = useState<ISponsor[]>([])
    const [platinumSponsors, setPlatinumSponsors] = useState<ISponsor[]>([])
    const [goldSponsors, setGoldSponsors] = useState<ISponsor[]>([])
    const [silverSponsors, setSilverSponsors] = useState<ISponsor[]>([])
    const [bronzeSponsors, setBronzeSponsors] = useState<ISponsor[]>([])

    const fetchSponsors = (): void => {
        setLoading(true)

        getAllSponsors()
            .then((res: AxiosResponse) => {
                setSponsors(res.data.sponsors.all)
                setPlatinumSponsors(res.data.sponsors.platinumSponsors)
                setGoldSponsors(res.data.sponsors.goldSponsors)
                setSilverSponsors(res.data.sponsors.silverSponsors)
                setBronzeSponsors(res.data.sponsors.bronzeSponsors)
                setLoading(false)
            })
            .catch((err) => showSnackbar(err.response.data?.message))
    }

    useEffect(() => {
        fetchSponsors()
    }, [])

    return (
        <Grid container spacing={2} sx={{ my: 2, mx: 'auto', width: '95%' }}>
            {!loading && sponsors.length === 0 && <EmptyData />}
            {/* {sponsors.map((sponsor) => {
                const { _id: id } = sponsor
                return (
                    <Grid
                        key={id}
                        sx={{ mt: '2%', bt: '2%' }}
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        xl={4}
                    >
                        <Link to={`/sponsor-details/${id}`}>
                            <Card
                                sx={{
                                    width: '100%',
                                    background: '#F1F1F1',
                                    borderRadius: '10px',
                                }}
                            >
                                <CardContent>
                                    <img
                                        src={sponsor.logoUrl}
                                        style={{
                                            width: '100%',
                                            height: '150px',
                                        }}
                                        alt="img"
                                    />
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                )
            })} */}

            {platinumSponsors.length !== 0 && (
                <Grid item xs={12}>
                    <Divider orientation="horizontal" flexItem>
                        <Typography variant="h4" component="h5">
                            Platinum Sponsors
                        </Typography>
                    </Divider>
                </Grid>
            )}

            {platinumSponsors.length !== 0 &&
                platinumSponsors.map((sponsor) => {
                    const { _id: id } = sponsor
                    return (
                        <Grid
                            key={id}
                            sx={{ mt: '2%', bt: '2%' }}
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                            xl={4}
                        >
                            <Link to={`/sponsor-details/${id}`}>
                                <Card
                                    sx={{
                                        width: '100%',
                                        background: '#F1F1F1',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <CardContent>
                                        <img
                                            src={sponsor.logoUrl}
                                            style={{
                                                width: '100%',
                                                height: '150px',
                                            }}
                                            alt="img"
                                        />
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    )
                })}

            {goldSponsors.length !== 0 && (
                <Grid item xs={12}>
                    <Divider orientation="horizontal" flexItem>
                        <Typography variant="h4" component="h5">
                            Gold Sponsors
                        </Typography>
                    </Divider>
                </Grid>
            )}

            {goldSponsors.length !== 0 &&
                goldSponsors.map((sponsor) => {
                    const { _id: id } = sponsor
                    return (
                        <Grid
                            key={id}
                            sx={{ mt: '2%', bt: '2%' }}
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                            xl={4}
                        >
                            <Link to={`/sponsor-details/${id}`}>
                                <Card
                                    sx={{
                                        width: '100%',
                                        background: '#F1F1F1',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <CardContent>
                                        <img
                                            src={sponsor.logoUrl}
                                            style={{
                                                width: '100%',
                                                height: '150px',
                                            }}
                                            alt="img"
                                        />
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    )
                })}

            {silverSponsors.length !== 0 && (
                <Grid item xs={12}>
                    <Divider orientation="horizontal" flexItem>
                        <Typography variant="h4" component="h5">
                            Silver Sponsors
                        </Typography>
                    </Divider>
                </Grid>
            )}

            {silverSponsors.length !== 0 &&
                silverSponsors.map((sponsor) => {
                    const { _id: id } = sponsor
                    return (
                        <Grid
                            key={id}
                            sx={{ mt: '2%', bt: '2%' }}
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                            xl={4}
                        >
                            <Link to={`/sponsor-details/${id}`}>
                                <Card
                                    sx={{
                                        width: '100%',
                                        background: '#F1F1F1',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <CardContent>
                                        <img
                                            src={sponsor.logoUrl}
                                            style={{
                                                width: '100%',
                                                height: '150px',
                                            }}
                                            alt="img"
                                        />
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    )
                })}

            {bronzeSponsors.length !== 0 && (
                <Grid item xs={12}>
                    <Divider orientation="horizontal" flexItem>
                        <Typography variant="h4" component="h5">
                            Bronze Sponsors
                        </Typography>
                    </Divider>
                </Grid>
            )}

            {bronzeSponsors.length !== 0 &&
                bronzeSponsors.map((sponsor) => {
                    const { _id: id } = sponsor
                    return (
                        <Grid
                            key={id}
                            sx={{ mt: '2%', bt: '2%' }}
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                            xl={4}
                        >
                            <Link to={`/sponsor-details/${id}`}>
                                <Card
                                    sx={{
                                        width: '100%',
                                        background: '#F1F1F1',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <CardContent>
                                        <img
                                            src={sponsor.logoUrl}
                                            style={{
                                                width: '100%',
                                                height: '150px',
                                            }}
                                            alt="img"
                                        />
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    )
                })}

            {/* {hasMore && !loading && (
                <Grid item xs={12} textAlign="center">
                    <Button
                        variant="contained"
                        onClick={() => {
                            setPagination((prevPagination) => {
                                return {
                                    ...prevPagination,
                                    page: Number(prevPagination.page) + 1,
                                }
                            })
                        }}
                    >
                        Load More
                    </Button>
                </Grid>
            )} */}
            {loading && (
                <Grid item xs={12}>
                    <Typography variant="h6" component="p" textAlign="center">
                        Loading...
                    </Typography>
                </Grid>
            )}
        </Grid>
    )
}
