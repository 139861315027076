import * as React from 'react'
import { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import Routes from './routes/Routes'
import {
    userContext,
    searchContext,
    drawerContext,
    adminUserContext,
    MessageContainer,
} from './context'
import SnackbarToast from './components/Snackbar'
import { getProfileAccess, getAdminFromLocalStorage } from './Services'

import './css/style.css'
import Loader from './components/Loader/Index'

export default function App(): ReactNode {
    const [user, setUser] = useState(null)
    const [adminUser, setAdminUser] = useState(null)
    const [value, setValue] = useState('')
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const profile = getProfileAccess()
        if (profile) {
            setUser(profile)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        const loggedinUser = getAdminFromLocalStorage()
        if (loggedinUser) {
            setAdminUser(loggedinUser)
        }
        setLoading(false)
    }, [])

    return (
        <HelmetProvider>
            <MessageContainer>
                <BrowserRouter>
                    <adminUserContext.Provider
                        value={{ adminUser, setAdminUser }}
                    >
                        <userContext.Provider value={{ user, setUser }}>
                            <searchContext.Provider value={{ value, setValue }}>
                                <drawerContext.Provider
                                    value={{ open, setOpen }}
                                >
                                    {loading ? <Loader /> : <Routes />}
                                </drawerContext.Provider>
                            </searchContext.Provider>
                        </userContext.Provider>
                    </adminUserContext.Provider>
                </BrowserRouter>
                <SnackbarToast />
            </MessageContainer>
        </HelmetProvider>
    )
}
