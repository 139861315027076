// import axios from 'axios'
import { callApi } from '../Services'

const baseURL = process.env.REACT_APP_BASE_URL

async function getAllMembers(): Promise<any> {
    try {
        const res = await callApi({
            path: '/allmembers',
        })
        return res.Members.Member
    } catch (err) {
        console.log(err)
        return err
    }
}
async function getMemberProfileById(id): Promise<any> {
    try {
        const res = await callApi({
            path: `/memberById/${id}`,
        })
        return res.Members.Member
    } catch (err) {
        console.log(err)
        return err
    }
}

async function getProfileImage(userID): Promise<any> {
    return new Promise((resolve, reject) => {
        fetch(`${baseURL}/user/profile-picture/${userID}`, {
            method: 'GET',
        })
            .then((res: Response) => resolve(res))
            .catch((err) => reject(err))
        return null
    })
}

async function updateProfileImage(userID, image): Promise<any> {
    const formData = new FormData()
    formData.append('file', image)
    formData.append('userID', userID)

    return new Promise((resolve, reject) => {
        fetch(`${baseURL}/upload/profile`, {
            method: 'POST',
            body: formData,
        })
            .then((res: Response) => resolve(res))
            .catch((err) => reject(err))
        return null
    })
}

// Function to merge duplicate member data
const mergeDuplicateData = (responseData: any): any => {
    const mergedData = {}

    // Merge data with the same MasterCustomerID
    responseData.forEach((item) => {
        const customerId = item.MasterCustomerID
        if (!mergedData[customerId]) {
            mergedData[customerId] = { ...item }
            // Convert single fields to arrays
            mergedData[customerId].BusinessLabelName = [
                mergedData[customerId].BusinessLabelName,
            ]
            mergedData[customerId].BusinessAddress1 = [
                mergedData[customerId].BusinessAddress1,
            ]
            mergedData[customerId].BusinessAddress2 = [
                mergedData[customerId].BusinessAddress2,
            ]
            mergedData[customerId].BusinessCity = [
                mergedData[customerId].BusinessCity,
            ]
            mergedData[customerId].BusinessCounty = [
                mergedData[customerId].BusinessCounty,
            ]
            mergedData[customerId].BusinessPostalCode = [
                mergedData[customerId].BusinessPostalCode,
            ]
            mergedData[customerId].BusinessState = [
                mergedData[customerId].BusinessState,
            ]
        } else {
            const mergedItem = mergedData[customerId]
            mergedItem.BusinessLabelName.push(item.BusinessLabelName)
            mergedItem.BusinessAddress1.push(item.BusinessAddress1)
            mergedItem.BusinessAddress2.push(item.BusinessAddress2)
            mergedItem.BusinessCity.push(item.BusinessCity)
            mergedItem.BusinessCounty.push(item.BusinessCounty)
            mergedItem.BusinessPostalCode.push(item.BusinessPostalCode)
            mergedItem.BusinessState.push(item.BusinessState)
        }
    })

    return Object.values(mergedData)
}

export {
    getAllMembers,
    getMemberProfileById,
    updateProfileImage,
    getProfileImage,
    mergeDuplicateData,
}
