import { Box } from '@mui/material'
import Profile from '../Profile'

export default function OneMemberDirectory(): JSX.Element {
    return (
        <Box>
            <Profile />
        </Box>
    )
}
