import { AxiosResponse } from 'axios'
import React, { useEffect, useState, useContext, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import {
    Grid,
    Box,
    Button,
    Typography,
    Card,
    CardHeader,
    CardMedia,
    CardActions,
    IconButton,
    Divider,
} from '@mui/material'
import {
    Facebook,
    Twitter,
    LinkedIn,
    Edit,
    Delete,
    Add,
} from '@mui/icons-material'

import Navbar from '../../components/Navbar'
import { getAllSponsors, deleteSponsorById } from '../../Services/sponsors'
import {
    SPONSOR_TYPES,
    SponsorModal,
} from '../../components/Modal/SponsorModal'
import { MessageContext, IMessageContext } from '../../context/MessageContext'
import EmptyData from '../../components/EmptyData'
import { DeleteModal } from '../../components/Modal/DeleteModal'
import SponsorCard from '../../components/Card/SponsorCard'

const ManageSponsors = (): ReactNode => {
    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
    const [editId, setEditId] = useState<string>('...')
    const [deleteId, setDeleteId] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [modalData, setModalData] = useState({} as ISponsor)
    const [modalIs, setModalIs] = useState<'Edit' | 'Add'>('Add')
    const [sponsors, setSponsors] = React.useState<ISponsor[]>([])
    const [platinumSponsors, setPlatinumSponsors] = React.useState<ISponsor[]>(
        []
    )
    const [goldSponsors, setGoldSponsors] = React.useState<ISponsor[]>([])
    const [silverSponsors, setSilverSponsors] = React.useState<ISponsor[]>([])
    const [bronzeSponsors, setBronzeSponsors] = React.useState<ISponsor[]>([])

    const handleEditButtonClick = (
        id: string,
        sponsorType: SPONSOR_TYPES
    ): void => {
        // eslint-disable-next-line no-underscore-dangle
        // setModalData(sponsors.find((sponsor) => sponsor._id === id))
        setEditId(id)
        setModalIs('Edit')
        setIsModalOpen(true)
    }

    const fetchSponsors = (): void => {
        setLoading(true)

        getAllSponsors()
            .then((res: AxiosResponse) => {
                setSponsors(res.data.sponsors.all)
                setPlatinumSponsors(res.data.sponsors.platinumSponsors)
                setGoldSponsors(res.data.sponsors.goldSponsors)
                setSilverSponsors(res.data.sponsors.silverSponsors)
                setBronzeSponsors(res.data.sponsors.bronzeSponsors)
                setLoading(false)
            })
            .catch((err) => showSnackbar(err.response.data?.message))
    }

    const handleCloseModal = (): void => {
        setIsModalOpen(false)
        setEditId('')
        setModalData({} as ISponsor)
        fetchSponsors()
    }

    const openDeleteModal = (id: string): void => {
        setDeleteId(id)
        setIsDeleteModalOpen(true)
    }

    const handleDeleteModalClose = (): void => {
        setDeleteId('')
        setIsDeleteModalOpen(false)
    }

    const deleteSponsor = (id: string): void => {
        setLoading(true)
        deleteSponsorById(id)
            .then((res: AxiosResponse) => {
                showSnackbar(res.data?.message)
                fetchSponsors()
                handleDeleteModalClose()
                setLoading(false)
            })
            .catch((err) => {
                showSnackbar(err.response.data?.message)
                handleDeleteModalClose()
            })
    }

    useEffect(() => {
        fetchSponsors()
    }, [])

    const keyTest = useMemo(() => {
        return Math.random()
    }, [editId])

    return (
        <>
            <Helmet>
                <title>Manage Sponsors</title>
            </Helmet>
            <Navbar />

            <Grid container spacing={2} padding="2rem">
                <Grid item xs={12}>
                    <Typography variant="h4" component="p" textAlign="right">
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                                setModalData({} as ISponsor)
                                setModalIs('Add')
                                setIsModalOpen(true)
                            }}
                        >
                            Add Sponsor
                        </Button>
                    </Typography>
                </Grid>
                {!loading && sponsors.length === 0 && <EmptyData />}

                {platinumSponsors.length !== 0 && (
                    <Grid item xs={12}>
                        <Divider orientation="horizontal" flexItem>
                            <Typography variant="h4" component="h5">
                                Platinum Sponsors
                            </Typography>
                        </Divider>
                    </Grid>
                )}

                {platinumSponsors.length !== 0 &&
                    platinumSponsors.map((sponsor) => {
                        const { _id: id } = sponsor
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
                                <SponsorCard
                                    sponsor={sponsor}
                                    sponsorType={sponsor.sponsorType}
                                    handleEditButtonClick={
                                        handleEditButtonClick
                                    }
                                    openDeleteModal={openDeleteModal}
                                />
                            </Grid>
                        )
                    })}

                {goldSponsors.length !== 0 && (
                    <Grid item xs={12}>
                        <Divider orientation="horizontal" flexItem>
                            <Typography variant="h4" component="h5">
                                Gold Sponsors
                            </Typography>
                        </Divider>
                    </Grid>
                )}

                {goldSponsors.length !== 0 &&
                    goldSponsors.map((sponsor) => {
                        const { _id: id } = sponsor
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
                                <SponsorCard
                                    sponsor={sponsor}
                                    sponsorType={sponsor.sponsorType}
                                    handleEditButtonClick={
                                        handleEditButtonClick
                                    }
                                    openDeleteModal={openDeleteModal}
                                />
                            </Grid>
                        )
                    })}

                {silverSponsors.length !== 0 && (
                    <Grid item xs={12}>
                        <Divider orientation="horizontal" flexItem>
                            <Typography variant="h4" component="h5">
                                Silver Sponsors
                            </Typography>
                        </Divider>
                    </Grid>
                )}

                {silverSponsors.length !== 0 &&
                    silverSponsors.map((sponsor) => {
                        const { _id: id } = sponsor
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
                                <SponsorCard
                                    sponsor={sponsor}
                                    sponsorType={sponsor.sponsorType}
                                    handleEditButtonClick={
                                        handleEditButtonClick
                                    }
                                    openDeleteModal={openDeleteModal}
                                />
                            </Grid>
                        )
                    })}

                {bronzeSponsors.length !== 0 && (
                    <Grid item xs={12}>
                        <Divider orientation="horizontal" flexItem>
                            <Typography variant="h4" component="h5">
                                Bronze Sponsors
                            </Typography>
                        </Divider>
                    </Grid>
                )}

                {bronzeSponsors.length !== 0 &&
                    bronzeSponsors.map((sponsor) => {
                        const { _id: id } = sponsor
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
                                <SponsorCard
                                    sponsor={sponsor}
                                    sponsorType={sponsor.sponsorType}
                                    handleEditButtonClick={
                                        handleEditButtonClick
                                    }
                                    openDeleteModal={openDeleteModal}
                                />
                            </Grid>
                        )
                    })}

                {loading && (
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            component="p"
                            textAlign="center"
                        >
                            Loading...
                        </Typography>
                    </Grid>
                )}

                <SponsorModal
                    id={editId}
                    // key={keyTest}
                    open={isModalOpen}
                    modalAction={modalIs}
                    data={modalData}
                    handleClose={handleCloseModal}
                />

                <DeleteModal
                    open={isDeleteModalOpen}
                    dialogTitle="Are you sure you want to delete this sponsor?"
                    onClose={handleDeleteModalClose}
                    deleting={loading}
                    onDeleteConfirm={() => deleteSponsor(deleteId)}
                />
            </Grid>
        </>
    )
}

export default ManageSponsors
