import { AxiosResponse } from 'axios'
import { useEffect, useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Typography, Box, Button } from '@mui/material'
import { ChevronLeft } from '@mui/icons-material'
import Image from '../../components/Image'
import FacebookIcon from '../../img/facebook.svg'
import TwitterIcon from '../../img/twitter.svg'
import LinkedonIcon from '../../img/linkedin.svg'
import InboxxIcon from '../../img/inbox.svg'
import TelephoneIcon from '../../img/telephone.svg'
import MobileIcon from '../../img/mobile.svg'
import WebIcon from '../../img/web.svg'
import { GlbobalList, GlobalDivider } from '../../style'
import AppList from '../../components/List/AppList'
import { getSponsorById } from '../../Services/sponsors'
import { MessageContext, IMessageContext } from '../../context/MessageContext'

const SponsorDetails = (): JSX.Element => {
    const navigate = useNavigate()
    const { showSnackbar } = useContext(MessageContext) as IMessageContext

    const [sponsor, setSponsor] = useState<ISponsor>({} as ISponsor)
    const [loading, setLoading] = useState<boolean>(true)
    const { sponsorId } = useParams()
    const data = [
        {
            primaryName: sponsor?.email,
            secondaryName: 'Sponsor Email',
            icon: InboxxIcon,
            subSecondaryName: null,
        },
        {
            primaryName: sponsor?.primaryPhone,
            secondaryName: 'Primary',
            icon: TelephoneIcon,
            subSecondaryName: null,
        },
        {
            primaryName: sponsor?.corporatePhone,
            secondaryName: 'Corporate',
            icon: MobileIcon,
            subSecondaryName: null,
        },
        {
            primaryName: sponsor?.websiteUrl,
            secondaryName: 'Website',
            icon: WebIcon,
            subSecondaryName: null,
        },
    ]

    const fetchSponsor = (): void => {
        getSponsorById(sponsorId)
            .then((res: AxiosResponse) => {
                setSponsor(res.data)
                setLoading(false)
            })
            .catch((err) => {
                showSnackbar(err.response.data.error.message, true)
            })
    }

    useEffect(() => {
        fetchSponsor()
    }, [])

    if (loading) {
        return (
            <Box marginTop={3}>
                <Typography variant="h6" component="p" textAlign="center">
                    Loading...
                </Typography>
            </Box>
        )
    }

    return (
        <Box width="90%" margin="auto" marginTop={3}>
            <Box width="100%" sx={{ mb: 4 }}>
                <Button
                    variant="contained"
                    color="warning"
                    onClick={() => navigate(-1)}
                >
                    <ChevronLeft /> Back to Sponsors
                </Button>
            </Box>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom={3}
            >
                <Box width="200px">
                    <Image
                        src={sponsor.logoUrl}
                        alt="Company_Logo"
                        width="100%"
                        height="100%"
                        margin="0"
                    />
                </Box>
                <Box display="flex">
                    <a href={sponsor.facebookLink}>
                        <Image src={FacebookIcon} alt="facebook_icon" />
                    </a>
                    <a href={sponsor.twitterLink}>
                        <Image src={TwitterIcon} alt="twitter_icon" />
                    </a>
                    <a href={sponsor.linkedinLink}>
                        <Image src={LinkedonIcon} alt="linkedon_icon" />
                    </a>
                </Box>
            </Box>

            <Box marginTop="2rem">
                <Typography marginBottom="3px" variant="h4">
                    {sponsor.companyName}
                </Typography>
                <Typography marginBottom="3px">
                    {sponsor.contactName}
                </Typography>
                <Typography marginBottom="3px">
                    {sponsor.city}, {sponsor.state}
                </Typography>
            </Box>
            <GlobalDivider variant="fullWidth" mt="1.7rem" mb="10px" />

            <GlbobalList flex justify column={false}>
                {data.map((item, index) => {
                    return (
                        <AppList
                            key={item.primaryName}
                            isBorder={index !== data.length - 1}
                            isPrimaryTitle
                            primaryTitle={item.primaryName}
                            isSecondaryTitle
                            secondaryTitle={item.secondaryName}
                            isSubSecondaryTitle={!!item.subSecondaryName}
                            subSecondaryTitle={item.subSecondaryName}
                            isIcon={!!item.icon}
                            Icon={item.icon}
                        />
                    )
                })}
            </GlbobalList>
            <GlobalDivider variant="fullWidth" mt="10px" />
        </Box>
    )
}

export default SponsorDetails
