import React, { useEffect, useState, useContext } from 'react'
import {
    Box,
    Grid,
    Button,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material'
import _ from 'lodash'
import Card from '../../components/Card'
import { getAllMembers, mergeDuplicateData } from '../../Services'
import Loader from '../../components/Loader/Index'
import ToastAlert from '../../components/Alert'
import { searchContext } from '../../context'

function paginate(array, pageSize, pageNumber): Array<any> {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
}

export default function MemberDirectory(): ReactNode {
    const { value } = useContext(searchContext)
    const [serverResponseData, setServerResponseData] = useState([])
    const [data, setData] = useState([])
    const [error, setError] = useState('')
    const [limit] = useState(12)
    const [loader, setLoader] = useState(false)
    const [viewData, setViewData] = useState([])
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState('FirstName')

    async function getData(): Promise<any> {
        setLoader(true)
        let response = await getAllMembers()
        if (response.name === 'AxiosError') {
            setData([])
            setError('Network Error')
            setLoader(false)
        } else {
            response = _.orderBy(response, [sort], ['asc'])

            // If duplicate members with different business address, merge the duplicate items into an array and set the data value
            setServerResponseData(mergeDuplicateData(response))
            setData(mergeDuplicateData(response))
            setLoader(false)
            setError('')
        }
    }

    const getCompanyName = (
        city: string[] | null,
        state: string[] | null
    ): (string | null)[] => {
        if (city && state && city.length === state.length) {
            return city.map((c, index) => `${c}, ${state[index]}`)
        }
        return [null]
    }

    const handleNext = (): any => {
        if (data.length <= page * limit) {
            return
        }
        setPage(page + 1)
    }
    const handlePrevious = (): any => {
        if (page === 1) {
            return
        }
        setPage(page - 1)
    }

    // update the data by sorting them in the selected type
    useEffect(() => {
        setServerResponseData(_.orderBy(serverResponseData, [sort], ['asc']))
        setData(_.orderBy(data, [sort], ['asc']))
    }, [sort])

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        setPage(1)
        if (value === '') {
            setData(serverResponseData)
            setError('')
        } else {
            const filteredData = serverResponseData.filter((item) => {
                const fullName = `${item.FirstName.replace(
                    /\s/g,
                    ''
                ).toLowerCase()}${item.LastName.replace(
                    /\s/g,
                    ''
                ).toLowerCase()}`
                return fullName
                    .replace(/\s/g, '')
                    .includes(value.replace(/\s/g, '').toLowerCase())
            })
            if (filteredData.length < 1) {
                setError('No Data Found')
            } else {
                setError('')
            }
            setData(filteredData)
        }
    }, [value])

    useEffect(() => {
        setViewData(paginate(data, limit, page))
    }, [page, data])

    return (
        <Box>
            {/* <Typography variant="h3">Platinum Doctors</Typography>
            <Typography variant="subtitle2">
                Vestibulum ac diam sit amet quam vehicula elementum sed sit amet
                dui. 
            </Typography> */}
            {/* <Box>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Divider />
            </Box> */}

            {!loader ? (
                <>
                    {/* Sorting options here  */}
                    <FormControl style={{ margin: 20 }}>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                            Sort:
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={sort}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setSort((e.target as HTMLInputElement).value)}
                        >
                            <FormControlLabel
                                value="FirstName"
                                control={<Radio size="small" />}
                                label="First Name"
                            />
                            <FormControlLabel
                                value="LastName"
                                control={<Radio size="small" />}
                                label="Last Name"
                            />
                            <FormControlLabel
                                value="BusinessCity"
                                control={<Radio size="small" />}
                                label="City"
                            />
                        </RadioGroup>
                    </FormControl>

                    {/* Data here  */}
                    <Grid container sx={{ width: '95%', m: 'auto', mt: 2 }}>
                        {viewData.map((item, index) => (
                            <Grid
                                key={item.MasterCustomerID + index}
                                display="flex"
                                justifyContent="center"
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={3}
                                xl={2}
                            >
                                <Card
                                    img={item.img}
                                    companyName={getCompanyName(
                                        item.BusinessCity,
                                        item.BusinessState
                                    )}
                                    practiceName={item.BusinessLabelName}
                                    firstName={item.FirstName}
                                    lastName={item.LastName}
                                    id={item.MasterCustomerID}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    {data?.length > 0 && (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                                disabled={page === 1}
                                onClick={handlePrevious}
                            >
                                Previous
                            </Button>
                            <Button
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                                disabled={data.length <= page * limit}
                                onClick={handleNext}
                            >
                                Next
                            </Button>
                        </Box>
                    )}

                    {error && (
                        <Box sx={{ p: 5 }}>
                            <ToastAlert error={error} />
                        </Box>
                    )}
                </>
            ) : (
                <Loader />
            )}
        </Box>
    )
}
