import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { getProfileAccess, getAdminFromLocalStorage } from '../Services'

export const ProtectedRoute = ({ children }): JSX.Element => {
    const [user] = useState(getProfileAccess())
    const [superAdmin] = useState(getAdminFromLocalStorage())

    if (superAdmin.user && superAdmin.token) {
        ;<Navigate to="/manage-sponsors" />
    }

    return <>{!user ? <Navigate to="/login" /> : <>{children}</>}</>
}
