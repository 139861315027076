import { createContext } from 'react'
import { getAdminFromLocalStorage } from '../Services'

// eslint-disable-next-line @typescript-eslint/no-empty-function
const userContext = createContext({
    user: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setUser: (loggedInUser: any) => {},
})

// eslint-disable-next-line @typescript-eslint/no-empty-function
const adminUserContext = createContext({
    adminUser: getAdminFromLocalStorage(),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setAdminUser: (loggedInUser: any) => {},
})
export { userContext, adminUserContext }
