import { AxiosResponse } from 'axios'
import { useEffect, useState, useContext, useRef } from 'react'
import {
    Button,
    TextField,
    Grid,
    Box,
    InputAdornment,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@mui/material'
import * as Yup from 'yup'

import { AttachFile, Cancel } from '@mui/icons-material'
import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import { Modal } from './Modal'
import {
    addNewSponsor,
    editSponsor,
    getSponsorById,
    uploadImage,
} from '../../Services/sponsors'
import { MessageContext, IMessageContext } from '../../context/MessageContext'

export interface ISopnsorModal {
    id?: string
    data: ISponsor
    open: boolean
    modalAction: 'Edit' | 'Add'
    handleClose: () => void
}

export enum SPONSOR_TYPES {
    PLATINUM = 'platinum',
    GOLD = 'gold',
    SILVER = 'silver',
    BRONZE = 'bronze',
}

const emptySponsor = {
    companyName: '',
    contactName: '',
    primaryPhone: '',
    corporatePhone: '',
    city: '',
    state: '',
    email: '',
    sponsorType: '',
    logoUrl: '',
    websiteUrl: '',
    facebookLink: '',
    twitterLink: '',
    linkedinLink: '',
}

export const SponsorModal = ({
    id,
    modalAction,
    open,
    handleClose,
}: ISopnsorModal): ReactNode => {
    const [sponsor, setSponsor] = useState<ISponsor>(emptySponsor as ISponsor)
    const [loading, setLoading] = useState<boolean>(false)
    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const imgInputRef = useRef(null)
    const [previewImage, setPreviewImage] = useState(null)
    const [previewImageSrc, setPreviewImageSrc] = useState<string>('')

    const handleModalOnClose = (formik): void => {
        formik.resetForm()
        setSponsor(emptySponsor as ISponsor)
        setPreviewImage(null)
        setPreviewImageSrc('')
        handleClose()
    }

    const handleSubmit = async (values, formik): Promise<any> => {
        const formData = values
        if (previewImage) {
            await uploadImage(previewImage)
                .then(async (res) => {
                    const parsedResponse = await res.json()
                    formData.logoUrl = parsedResponse.url
                })
                .catch((err) => {
                    showSnackbar('Please select a image file.', true)
                })
        }
        console.log(formData)

        if (modalAction === 'Add') {
            addNewSponsor(formData)
                .then((res: AxiosResponse) => {
                    handleModalOnClose(formik)
                    showSnackbar(res.data.message, false)
                })
                .catch((err) => {
                    showSnackbar('Sponsor Add Failed', true)
                    formik.setErrors(err.response.data?.error)
                })
        }

        if (modalAction === 'Edit') {
            // eslint-disable-next-line no-underscore-dangle
            editSponsor(sponsor._id, formData)
                .then((res: AxiosResponse) => {
                    handleModalOnClose(formik)
                    showSnackbar(res.data.message, false)
                })
                .catch((err) => {
                    setPreviewImage(null)
                    setPreviewImageSrc('')
                    showSnackbar('Sponsor Update Failed', true)
                    formik.setErrors(err.response.data?.error)
                })
        }
    }

    // const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/

    const formik = useFormik({
        initialValues: loading
            ? {
                  companyName: 'Loading...',
                  contactName: 'Loading...',
                  primaryPhone: 'Loading...',
                  corporatePhone: 'Loading...',
                  city: 'Loading...',
                  state: 'Loading...',
                  email: 'Loading...',
                  logoUrl: 'Loading...',
                  websiteUrl: 'Loading...',
                  facebookLink: 'Loading...',
                  twitterLink: 'Loading...',
                  linkedinLink: 'Loading...',
              }
            : {
                  // eslint-disable-next-line no-underscore-dangle
                  id: sponsor._id,
                  companyName: sponsor.companyName,
                  contactName: sponsor.contactName,
                  primaryPhone: sponsor.primaryPhone,
                  corporatePhone: sponsor.corporatePhone,
                  city: sponsor.city,
                  state: sponsor.state,
                  email: sponsor.email,
                  sponsorType: sponsor.sponsorType || '',
                  logoUrl: sponsor.logoUrl,
                  websiteUrl: sponsor.websiteUrl,
                  facebookLink: sponsor.facebookLink,
                  twitterLink: sponsor.twitterLink,
                  linkedinLink: sponsor.linkedinLink,
              },

        validationSchema: Yup.object({
            logoUrl: Yup.string().required('Logo is required'),
            sponsorType: Yup.string().required('Sponsor Type is required'),
        }),

        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            await handleSubmit(values, formik)
            setSubmitting(false)
        },

        enableReinitialize: true,
        validateOnBlur: true,
    })

    const fetchSponsor = (): void => {
        setLoading(true)
        getSponsorById(id)
            .then((res) => {
                setSponsor(res?.data)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }

    const handleImageChange = (event): void => {
        event.preventDefault()
        const file = event.target.files[0]
        if (file) {
            if (
                file.type !== 'image/jpeg' &&
                file.type !== 'image/png' &&
                file.type !== 'image/jpg' &&
                file.type !== 'image/x-png' &&
                file.type !== 'image/bmp' &&
                file.type !== 'image/gif'
            ) {
                showSnackbar('Please select only image file.', true)
                return
            }
            formik.setFieldValue('logoUrl', file.name)
            setPreviewImage(file)
            setPreviewImageSrc(URL.createObjectURL(file))
        }
    }

    const handleSponsorTypeChange = (event): void => {
        formik.setFieldValue('sponsorType', event.target.value || '')
    }

    useEffect(() => {
        if (modalAction === 'Edit' && id) {
            fetchSponsor()
        }
        if (modalAction === 'Add') {
            setSponsor({
                companyName: '',
                contactName: '',
                primaryPhone: '',
                corporatePhone: '',
                city: '',
                state: '',
                email: '',
                sponsorType: '',
                logoUrl: '',
                websiteUrl: '',
                facebookLink: '',
                twitterLink: '',
                linkedinLink: '',
            } as ISponsor)
        }
    }, [id, modalAction])

    return (
        <>
            <Modal
                dialogTitle={`${modalAction} Sponsor`}
                open={open}
                onClose={() => handleModalOnClose(formik)}
            >
                <Box component="form" onSubmit={formik.handleSubmit}>
                    <TextField
                        margin="normal"
                        id="companyName"
                        name="companyName"
                        label="Sponsor Name"
                        error={
                            formik.touched.companyName &&
                            Boolean(formik.errors.companyName)
                        }
                        helperText={
                            formik.touched.companyName &&
                            formik.errors.companyName
                        }
                        {...formik.getFieldProps('companyName')}
                        type="text"
                        fullWidth
                    />
                    <TextField
                        margin="normal"
                        id="contactName"
                        name="contactName"
                        label="Contact Name"
                        type="text"
                        error={
                            formik.touched.contactName &&
                            Boolean(formik.errors.contactName)
                        }
                        helperText={
                            formik.touched.contactName &&
                            formik.errors.contactName
                        }
                        {...formik.getFieldProps('contactName')}
                        fullWidth
                    />
                    <TextField
                        multiline
                        margin="normal"
                        id="email"
                        name="email"
                        label="Email"
                        type="text"
                        error={
                            formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                        {...formik.getFieldProps('email')}
                        fullWidth
                    />

                    <FormControl fullWidth margin="normal">
                        <InputLabel id="sponsorTypeLabel">
                            Sponsor Type
                        </InputLabel>
                        <Select
                            labelId="sponsorTypeLabel"
                            id="sponsorType"
                            name="sponsorType"
                            label="Sponsor Type"
                            error={
                                formik.touched.sponsorType &&
                                Boolean(formik.errors.sponsorType)
                            }
                            // {...formik.getFieldProps('sponsorType')}
                            // defaultValue={formik.values.sponsorType}
                            value={formik.values.sponsorType || ''}
                            onChange={handleSponsorTypeChange}
                        >
                            <MenuItem value={SPONSOR_TYPES.PLATINUM}>
                                Platinum
                            </MenuItem>
                            <MenuItem value={SPONSOR_TYPES.GOLD}>Gold</MenuItem>
                            <MenuItem value={SPONSOR_TYPES.SILVER}>
                                Silver
                            </MenuItem>
                            <MenuItem value={SPONSOR_TYPES.BRONZE}>
                                Bronze
                            </MenuItem>
                        </Select>
                        <FormHelperText>
                            {formik.touched.sponsorType &&
                                formik.errors.sponsorType}
                        </FormHelperText>
                    </FormControl>

                    <TextField
                        margin="normal"
                        id="primaryPhone"
                        name="primaryPhone"
                        label="Primary Phone"
                        type="tel"
                        error={
                            formik.touched.primaryPhone &&
                            Boolean(formik.errors.primaryPhone)
                        }
                        helperText={
                            formik.touched.primaryPhone &&
                            formik.errors.primaryPhone
                        }
                        {...formik.getFieldProps('primaryPhone')}
                        fullWidth
                    />
                    <TextField
                        margin="normal"
                        id="corporatePhone"
                        name="corporatePhone"
                        label="Corporate Phone"
                        type="tel"
                        error={
                            formik.touched.corporatePhone &&
                            Boolean(formik.errors.corporatePhone)
                        }
                        helperText={
                            formik.touched.corporatePhone &&
                            formik.errors.corporatePhone
                        }
                        {...formik.getFieldProps('corporatePhone')}
                        fullWidth
                    />
                    <TextField
                        margin="normal"
                        id="websiteUrl"
                        name="websiteUrl"
                        label="Website"
                        type="url"
                        error={
                            formik.touched.websiteUrl &&
                            Boolean(formik.errors.websiteUrl)
                        }
                        helperText={
                            formik.touched.websiteUrl &&
                            formik.errors.websiteUrl
                        }
                        {...formik.getFieldProps('websiteUrl')}
                        fullWidth
                    />
                    <TextField
                        margin="normal"
                        label="Logo"
                        placeholder="Logo"
                        name="logoUrl"
                        fullWidth
                        error={
                            formik.touched.logoUrl &&
                            Boolean(formik.errors.logoUrl)
                        }
                        helperText={
                            formik.touched.logoUrl && formik.errors.logoUrl
                        }
                        InputProps={{
                            readOnly: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AttachFile />
                                </InputAdornment>
                            ),
                        }}
                        value={
                            sponsor.logoUrl && !previewImage
                                ? sponsor.logoUrl
                                : previewImage?.name || ''
                        }
                        onClick={() => {
                            if (imgInputRef?.current !== undefined) {
                                imgInputRef?.current?.click()
                            }
                        }}
                    />
                    <input
                        hidden
                        id="img-upload"
                        ref={imgInputRef}
                        accept="image/x-png,image/png,image/bmp,image/gif,image/jpeg,image/jpg"
                        type="file"
                        onChange={(e) => {
                            handleImageChange(e)
                        }}
                    />

                    {previewImageSrc && (
                        <Box width="100%" sx={{ textAlign: 'right' }}>
                            <IconButton
                                onClick={() => {
                                    setPreviewImage(null)
                                    setPreviewImageSrc('')
                                }}
                            >
                                <Cancel sx={{ color: 'red' }} />
                            </IconButton>
                            <Box
                                component="img"
                                src={previewImageSrc}
                                width="100%"
                                maxHeight={200}
                            />
                        </Box>
                    )}

                    {sponsor.logoUrl && !previewImage && (
                        <Box width="100%">
                            <Box
                                component="img"
                                src={sponsor.logoUrl}
                                alt="Sponsor Logo"
                                width="100%"
                                maxHeight={200}
                                display="block"
                            />
                        </Box>
                    )}

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                margin="normal"
                                id="city"
                                name="city"
                                label="City"
                                error={
                                    formik.touched.city &&
                                    Boolean(formik.errors.city)
                                }
                                helperText={
                                    formik.touched.city && formik.errors.city
                                }
                                {...formik.getFieldProps('city')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="normal"
                                id="state"
                                name="state"
                                label="State"
                                error={
                                    formik.touched.state &&
                                    Boolean(formik.errors.state)
                                }
                                helperText={
                                    formik.touched.state && formik.errors.state
                                }
                                {...formik.getFieldProps('state')}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        margin="normal"
                        id="facebookLink"
                        name="facebookLink"
                        label="Facebook Link"
                        type="url"
                        error={
                            formik.touched.facebookLink &&
                            Boolean(formik.errors.facebookLink)
                        }
                        helperText={
                            formik.touched.facebookLink &&
                            formik.errors.facebookLink
                        }
                        {...formik.getFieldProps('facebookLink')}
                        fullWidth
                    />
                    <TextField
                        margin="normal"
                        id="twitterLink"
                        name="twitterLink"
                        label="Twitter Link"
                        type="url"
                        error={
                            formik.touched.twitterLink &&
                            Boolean(formik.errors.twitterLink)
                        }
                        helperText={
                            formik.touched.twitterLink &&
                            formik.errors.twitterLink
                        }
                        {...formik.getFieldProps('twitterLink')}
                        fullWidth
                    />
                    <TextField
                        margin="normal"
                        id="linkedinLink"
                        name="linkedinLink"
                        label="LinkedIn Link"
                        type="url"
                        error={
                            formik.touched.linkedinLink &&
                            Boolean(formik.errors.linkedinLink)
                        }
                        helperText={
                            formik.touched.linkedinLink &&
                            formik.errors.linkedinLink
                        }
                        {...formik.getFieldProps('linkedinLink')}
                        fullWidth
                    />
                    <Box display="flex" textAlign="right" marginTop={3}>
                        <Button
                            onClick={() => handleModalOnClose(formik)}
                            variant="contained"
                            color="error"
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            endIcon={<></>}
                            loadingPosition="end"
                            type="submit"
                            variant="contained"
                            loading={formik.isSubmitting || loading}
                            disabled={formik.isSubmitting || loading}
                            sx={{ ml: 2, flexGrow: 1 }}
                        >
                            {modalAction} Sponsor
                        </LoadingButton>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}
