import { Box, Typography, Divider, Avatar, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

interface CardPropTyes {
    img: string
    practiceName: string[]
    companyName: string[]
    firstName: string
    lastName: string
    id: number
}

export default function Card({
    img,
    practiceName,
    companyName,
    firstName,
    lastName,
    id,
}: CardPropTyes): JSX.Element {
    const navigate = useNavigate()
    const Btn = styled(Button)`
        color: #015790;
        background: #d9d9d9;
        border-radius: 24px;
        padding: 10px 22px;
        margin: 1rem auto !important;
    `
    return (
        <Box
            sx={{
                mt: 2,
                width: '300px',
                height: '450px',
                padding: '2rem 1rem',
                margin: '1rem 1rem',
                border: '2px solid #efefef',
                borderRadius: '10px',
            }}
        >
            <Avatar
                alt="avatar"
                style={{ width: '100px', height: '100px', margin: 'auto' }}
                src={img}
            />
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
            >
                <Typography variant="h6" sx={{ pt: 1, whiteSpace: 'nowrap' }}>
                    {firstName && firstName} {lastName && lastName}
                </Typography>
                <Divider sx={{ width: '50%' }} />

                {/* If the name is more than one, map them with the address one by one  */}
                {practiceName.length > 2
                    ? practiceName
                          .slice(0, 2)
                          .map((name: string, index: number) => (
                              <div key={index}>
                                  <Typography
                                      variant="subtitle1"
                                      sx={{
                                          fontSize: '14px',
                                          textAlign: 'center',
                                      }}
                                  >
                                      {name}
                                  </Typography>
                                  {companyName[0].trim() !== ',' && (
                                      <Typography
                                          sx={{
                                              height: '42px',
                                              fontSize: '14px',
                                              textAlign: 'center',
                                          }}
                                      >
                                          {companyName[index]}
                                      </Typography>
                                  )}
                              </div>
                          ))
                    : // If the length is 2 or less, map all items
                      practiceName.map((name: string, index: number) => (
                          <div key={index}>
                              <Typography
                                  variant="subtitle1"
                                  sx={{ fontSize: '14px', textAlign: 'center' }}
                              >
                                  {name}
                              </Typography>
                              {companyName[0].trim() !== ',' && (
                                  <Typography
                                      sx={{
                                          height: '42px',
                                          fontSize: '14px',
                                          textAlign: 'center',
                                      }}
                                  >
                                      {companyName[index]}
                                  </Typography>
                              )}
                          </div>
                      ))}

                {/* <Typography sx={{ height: '42px', fontSize: '14px' }}>
                    {companyName && companyName}
                </Typography> */}
            </Box>
            <Btn
                sx={{ py: 0, display: 'block', margin: 'auto' }}
                onClick={() => {
                    navigate(`/member-directory/${id}`)
                }}
            >
                Read More
            </Btn>
        </Box>
    )
}
