/* eslint-disable consistent-return */
import axios, { AxiosError, AxiosResponse } from 'axios'

const baseURL = process.env.REACT_APP_BASE_URL

const adminAuthorizedRequest = axios.create({
    baseURL,
})

adminAuthorizedRequest.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `Bearer ${window.localStorage.getItem(
            'adminToken'
        )}`
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)
export async function getLink(): Promise<any> {
    return new Promise((resolve, reject) => {
        adminAuthorizedRequest
            .get(`${baseURL}/link`)
            .then((res: AxiosResponse) => resolve(res))
            .catch((err: AxiosError) => reject(err))
    })
}
