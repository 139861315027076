import { Avatar, Box, Button } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import {
    updateProfileImage,
    getProfileImage,
    getProfileAccess,
} from '../../Services'
import Loader from '../Loader/Index'
import { MessageContext, IMessageContext } from '../../context/MessageContext'

interface IUserProfilePictureProps {
    userID: string
    alt?: string
}

const UserProfilePicture = ({
    userID,
    alt = 'Profile Picture',
}: IUserProfilePictureProps): ReactNode => {
    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const imgInputRef = useRef<HTMLInputElement>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [loggedinUser] = useState(getProfileAccess())
    const [profileSource, setProfileSource] = useState<string>('#')

    const handleImageChange = async (event): Promise<void> => {
        const profileImage = event.target.files[0]
        if (profileImage) {
            if (
                profileImage.type !== 'image/jpeg' &&
                profileImage.type !== 'image/png' &&
                profileImage.type !== 'image/jpg' &&
                profileImage.type !== 'image/x-png' &&
                profileImage.type !== 'image/bmp' &&
                profileImage.type !== 'image/gif'
            ) {
                showSnackbar('Please select only image file.', true)
                return
            }

            setLoading(true)
            await updateProfileImage(userID, profileImage)
                .then(async (res) => {
                    const parsedResponse = await res.json()
                    setProfileSource(parsedResponse.profileImageUrl)
                    setLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    // showSnackbar('Please select a image file.', true)
                })
        }
    }

    const fetchProfileImage = (): void => {
        getProfileImage(userID)
            .then(async (res) => {
                const parsedResponse = await res.json()
                setProfileSource(parsedResponse.profileImageUrl)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        fetchProfileImage()
    }, [])

    return (
        <Box display="flex" flexDirection="column" sx={{ mr: 2 }}>
            {loading ? (
                <Loader sx={{ height: 100, width: 100 }} />
            ) : (
                <Avatar
                    src={profileSource}
                    sx={{
                        width: 100,
                        height: 100,
                        border: '1px solid #b8b8b8',
                    }}
                    alt={alt}
                />
            )}

            {loggedinUser?.MasterCustomerID === userID && (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        sx={{ mt: 2 }}
                        onClick={() => {
                            if (imgInputRef?.current !== undefined) {
                                imgInputRef?.current?.click()
                            }
                        }}
                    >
                        Update
                    </Button>

                    <input
                        hidden
                        id="img-upload"
                        ref={imgInputRef}
                        accept="image/x-png,image/png,image/bmp,image/gif,image/jpeg,image/jpg"
                        type="file"
                        onChange={(e) => {
                            handleImageChange(e)
                        }}
                    />
                </>
            )}
        </Box>
    )
}

export default UserProfilePicture
