import { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Grid, Box, TextField, Typography, Theme } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
    loginAsAdmin,
    addAdminUserToLocalStorage,
} from '../../Services/authService'
import { adminUserContext } from '../../context/UserContext'
import Logo from '../../components/Logo'
import { MessageContext, IMessageContext } from '../../context/MessageContext'

const AdminLogin = (): ReactNode => {
    const { adminUser, setAdminUser } = useContext(adminUserContext)
    const { showSnackbar } = useContext(MessageContext) as IMessageContext

    const navigate = useNavigate()

    useEffect(() => {
        if (adminUser.token || adminUser.user) {
            navigate('/manage-sponsors')
        }
    }, [adminUser.token, adminUser.user])

    const loginHandler = async (values): Promise<any> => {
        try {
            const response = await loginAsAdmin(values.email, values.password)
            if (response) {
                if (response.user) {
                    addAdminUserToLocalStorage(response)
                    setAdminUser(response)
                    showSnackbar('Login Successful', false)
                }
                if (response.message) {
                    addAdminUserToLocalStorage(null)
                    showSnackbar(response.message, true)
                }
            }
        } catch (err) {
            showSnackbar(err.response.data.error, true)
        }
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .min(3, 'Mininum 3 characters ')
                .max(255)
                .required('UserName is required'),
            password: Yup.string()
                .min(6, 'Password is too short - should be 6 chars minimum.')
                .required('Password is required'),
        }),
        onSubmit: loginHandler,
        validateOnChange: true,
    })

    return (
        <>
            <Helmet>
                <title>Admin Login</title>
            </Helmet>

            <Grid
                container
                component="main"
                alignItems="center"
                justifyItems="center"
                justifyContent="center"
                textAlign="center"
                sx={{
                    height: '100vh',
                    p: (theme: Theme) => theme.spacing(2),
                }}
            >
                <Grid item xs={12} sm={6} md={5} lg={3}>
                    <Logo />
                    <Typography component="h1" variant="h5">
                        Login as Admin
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={formik.handleSubmit}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            error={
                                formik.touched.email &&
                                Boolean(formik?.errors?.email)
                            }
                            helperText={formik?.errors?.email}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="User Name"
                            onChange={(event) => {
                                formik.setFieldError('email', '')
                                formik.setFieldValue(
                                    'email',
                                    event.target.value
                                )
                            }}
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            error={
                                formik.touched.password &&
                                Boolean(formik?.errors?.password)
                            }
                            helperText={formik?.errors?.password}
                            margin="normal"
                            required
                            fullWidth
                            onChange={(event) => {
                                formik.setFieldError('password', '')
                                formik.setFieldValue(
                                    'password',
                                    event.target.value
                                )
                            }}
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Typography
                            component="p"
                            variant="body2"
                            textAlign="right"
                        >
                            <Link
                                to="/login"
                                style={{
                                    textDecoration: 'underline',
                                }}
                            >
                                Click here to sign-in as User
                            </Link>
                        </Typography>
                        <LoadingButton
                            sx={{
                                '&.MuiButtonBase-root:hover': {
                                    backgroundColor: '#86A1C7',
                                    color: '#FFFFFF',
                                },
                                mt: 3,
                                mb: 2,
                            }}
                            endIcon={<></>}
                            loadingPosition="end"
                            type="submit"
                            size="large"
                            variant="contained"
                            loading={formik.isSubmitting}
                            disabled={formik.isSubmitting}
                            fullWidth
                        >
                            {formik.isSubmitting
                                ? 'Attempting Sign In'
                                : 'Sign In As Admin'}
                        </LoadingButton>

                        {/* <Button
                            variant="contained"
                            size="large"
                            fullWidth
                            sx={{
                                '&.MuiButtonBase-root:hover': {
                                    backgroundColor: '#86A1C7',
                                    color: '#FFFFFF',
                                },
                                mt: 3,
                                mb: 2,
                            }}
                            onClick={() => {
                                navigate('/manage-sponsors')
                            }}
                        >
                            Sign in as Admin
                        </Button> */}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default AdminLogin
