/* eslint-disable consistent-return */
import axios, { AxiosError, AxiosResponse } from 'axios'

const baseURL = process.env.REACT_APP_BASE_URL

const adminAuthorizedRequest = axios.create({
    baseURL,
})

adminAuthorizedRequest.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `Bearer ${window.localStorage.getItem(
            'adminToken'
        )}`
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

export const logoutAdminUser = (): void => {
    window.localStorage.removeItem('adminToken')
    window.localStorage.removeItem('adminUser')
    window.location.reload()
}

adminAuthorizedRequest.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.statusCode === 401) {
            logoutAdminUser()
        }
        return Promise.reject(error)
    }
)

export async function getAllSponsors(): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseURL}/sponsor`)
            .then((res: AxiosResponse) => resolve(res))
            .catch((err: AxiosError) => reject(err))
    })
}

export async function getSponsorById(id: string): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseURL}/sponsor/${id}`)
            .then((res: AxiosResponse) => resolve(res))
            .catch((err) => reject(err))
    })
}

export async function deleteSponsorById(
    id: string
): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        adminAuthorizedRequest
            .delete(`/sponsor/${id}`)
            .then((res: AxiosResponse) => resolve(res))
            .catch((err: AxiosError) => reject(err))
    })
}

export async function addNewSponsor(
    sponsorDetails
): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        adminAuthorizedRequest
            .post(`sponsor`, sponsorDetails)
            .then((res: AxiosResponse) => {
                resolve(res)
            })
            .catch((err: AxiosError) => reject(err))
    })
}

export async function editSponsor(
    id,
    sponsorDetails
): Promise<AxiosResponse | AxiosError> {
    return new Promise((resolve, reject) => {
        adminAuthorizedRequest
            .patch(`/sponsor/${id}`, sponsorDetails)
            .then((res: AxiosResponse) => resolve(res))
            .catch((err: AxiosError) => reject(err))
    })
}

export async function uploadImage(image): Promise<Response | any> {
    const formData = new FormData()
    formData.append('file', image)

    return new Promise((resolve, reject) => {
        if (!image) {
            return reject(new Error('No image file'))
        }
        fetch(`${baseURL}/upload/sponsorLogo`, {
            method: 'POST',
            body: formData,
        })
            .then((res: Response) => resolve(res))
            .catch((err) => reject(err))
    })
}
