import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const EmptyData = (): ReactNode => {
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6" component="p" textAlign="center">
                    No Sponsors!
                </Typography>
            </Grid>
        </>
    )
}

export default EmptyData
