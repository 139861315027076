import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Typography, Box, Divider, Button } from '@mui/material'
import UserProfilePicture from '../../components/UserProfilePicture'
import {
    getLink,
    getMemberProfileById,
    getProfileAccess,
    mergeDuplicateData,
} from '../../Services'
import {
    SubTitle,
    GlbobalList,
    GlobalDivider,
    PrimaryTitle,
    SecondaryTitle,
} from '../../style'
import AppList from '../../components/List/AppList'
import { HeadingAndIconBox, HeadingBox, MainBox, MultipleBox } from './style'
import InboxxIcon from '../../img/inbox.svg'
import TelephoneIcon from '../../img/telephone.svg'
import MobileIcon from '../../img/mobile.svg'

import AddressIcon from '../../img/address.svg'
import HomeIcon from '../../img/home.svg'
import LocationIcon from '../../img/location.svg'
import Loader from '../../components/Loader/Index'
import ToastAlert from '../../components/Alert'

const Profile = (): ReactNode => {
    const { id } = useParams()
    const [data, setData] = useState(null)
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState(null)
    const [small, setSmall] = useState(false)
    const [link, setLink] = useState(null)
    const [showAll, setShowAll] = useState(false)

    const handleToggleShowAll = (): void => {
        setShowAll(!showAll)
    }

    const dataSheet1 = [
        {
            primaryName: `${data?.FirstName} ${data?.LastName}`,
            secondaryName: Array.isArray(data?.BusinessLabelName)
                ? data?.BusinessLabelName[0]
                : data?.BusinessLabelName,
            icon: InboxxIcon,
            subSecondaryName: Array.isArray(data?.BusinessCity)
                ? `${data?.BusinessCity[0]},${data?.BusinessState[0]}`
                : `${data?.BusinessCity},${data?.BusinessState}`,
        },
        {
            primaryName: data?.WorkPhone,
            secondaryName: 'Primary',
            icon: TelephoneIcon,
            subSecondaryName: null,
        },
        {
            primaryName: data?.MobilePhone,
            secondaryName: 'Mobile',
            icon: MobileIcon,
            subSecondaryName: null,
        },
    ]

    const getExternalLink = async (): Promise<any> => {
        try {
            const response = await getLink()
            if (response) {
                setLink(response.data.link.link)
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function getProfileById(): Promise<any> {
        setLoader(true)
        try {
            const response = await getMemberProfileById(id)
            if (response) {
                if (response.length > 1) {
                    // If duplicate members with different business address, merge the duplicate items into an array and set the data value
                    setData(mergeDuplicateData(response)[0])
                } else {
                    setData(response)
                }
                setError(null)
            }

            if (response.name === 'AxiosError') {
                setError('Network Error')
            }
        } catch (err) {
            setError('Network Error')
        }
        setLoader(false)
    }
    const getProfile = (): any => {
        setLoader(true)
        const member = getProfileAccess()
        setData(member)
        setLoader(false)
    }

    // Function to track small devices
    const updateWidth = (): void => {
        if (window.innerWidth < 1300) {
            setSmall(true)
        } else {
            setSmall(false)
        }
    }

    useEffect(() => {
        getExternalLink()
        if (id) {
            getProfileById()
        } else {
            getProfile()
        }
    }, [])

    useEffect(() => {
        if (id) getProfileById()
    }, [id])

    // Handle the width of screen
    useEffect(() => {
        updateWidth()
        window.addEventListener('resize', updateWidth)
        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', updateWidth)
    }, [])

    return (
        <Box margin="100px auto" width="90%">
            {data && !error && (
                <MainBox>
                    <UserProfilePicture userID={data.MasterCustomerID} />
                    <Box width="100%">
                        <HeadingAndIconBox marginLeft="2rem">
                            {/* <HeadingBox marginLeft="2rem"> */}
                            <Box>
                                <Typography marginBottom="3px" variant="h6">
                                    {`${data?.FirstName} ${data?.LastName}`}
                                </Typography>

                                {Array.isArray(data?.BusinessLabelName) ? (
                                    <>
                                        {(showAll
                                            ? data.BusinessLabelName
                                            : data.BusinessLabelName.slice(0, 2)
                                        ).map((name: string, index: number) => (
                                            <Typography
                                                key={index}
                                                marginBottom="3px"
                                            >
                                                {name}
                                            </Typography>
                                        ))}
                                        {data.BusinessLabelName.length > 2 && (
                                            <Button
                                                style={{ paddingLeft: 0 }}
                                                onClick={handleToggleShowAll}
                                            >
                                                {showAll
                                                    ? 'View Less'
                                                    : 'View More'}
                                            </Button>
                                        )}
                                    </>
                                ) : (
                                    <Typography marginBottom="3px">
                                        {data?.BusinessLabelName}
                                    </Typography>
                                )}

                                {/* {Array.isArray(data?.BusinessLabelName) ? (
                                    data?.BusinessLabelName.map(
                                        (name: string, index: number) => (
                                            <Typography
                                                key={index}
                                                marginBottom="3px"
                                            >
                                                {name}
                                            </Typography>
                                        )
                                    )
                                ) : (
                                    <Typography marginBottom="3px">
                                        {data?.BusinessLabelName}
                                    </Typography>
                                )} */}

                                <SubTitle marginBottom="3px">
                                    {(data?.HomeCity || data?.HmeState) &&
                                        `${data?.HomeCity}  ,${data?.HomeState}`}
                                </SubTitle>
                            </Box>
                            <Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 2 }}
                                    onClick={() => {
                                        window.open(link, '_blank')
                                    }}
                                >
                                    Link
                                </Button>
                            </Box>
                            {/* </HeadingBox> */}
                            {/* <Box display="flex">
                                <Image src={FacebookIcon} alt="facebook_icon" />
                                <Image src={TwitterIcon} alt="twitter_icon" />
                                <Image src={LinkedonIcon} alt="linkedon_icon" />
                            </Box> */}
                        </HeadingAndIconBox>

                        <GlobalDivider variant="middle" mt="14px" mb="5px" />
                        <GlobalDivider variant="middle" />

                        <Box borderLeft="0.1em solid black" marginTop="2rem">
                            <Box marginLeft="2rem">
                                <GlbobalList flex justify column={false}>
                                    {dataSheet1.map((item, index) => {
                                        return (
                                            <AppList
                                                key={item.primaryName}
                                                isBorder={
                                                    index !==
                                                    dataSheet1.length - 1
                                                }
                                                isPrimaryTitle
                                                primaryTitle={item.primaryName}
                                                isSecondaryTitle
                                                secondaryTitle={
                                                    item.secondaryName
                                                }
                                                isSubSecondaryTitle={
                                                    !!item.subSecondaryName
                                                }
                                                subSecondaryTitle={
                                                    item.subSecondaryName
                                                }
                                                isIcon={!!item.icon}
                                                Icon={item.icon}
                                            />
                                        )
                                    })}
                                </GlbobalList>
                                <Box marginTop="2rem">
                                    <Typography variant="h6">Emails</Typography>
                                    <Box
                                        marginLeft="1.7rem"
                                        marginTop="1.5rem"
                                        marginBottom="1.5rem"
                                    >
                                        {data.WorkEmail && (
                                            <Box display="flex">
                                                <Typography
                                                    variant="h6"
                                                    component="span"
                                                    sx={{ mr: 1 }}
                                                >
                                                    Work:
                                                </Typography>
                                                <PrimaryTitle variant="h6">
                                                    {data?.WorkEmail}
                                                </PrimaryTitle>
                                            </Box>
                                        )}
                                        {data.HomeEmail && (
                                            <Box display="flex">
                                                <Typography
                                                    variant="h6"
                                                    component="span"
                                                    sx={{ mr: 1 }}
                                                >
                                                    Home:
                                                </Typography>
                                                <PrimaryTitle variant="h6">
                                                    {data?.HomeEmail}
                                                </PrimaryTitle>
                                            </Box>
                                        )}
                                        {data.OtherEmail && (
                                            <Box display="flex">
                                                <Typography
                                                    variant="h6"
                                                    component="span"
                                                    sx={{ mr: 1 }}
                                                >
                                                    Other:
                                                </Typography>
                                                <PrimaryTitle variant="h6">
                                                    {data?.OtherEmail}
                                                </PrimaryTitle>
                                            </Box>
                                        )}
                                    </Box>
                                    <Typography variant="h6">
                                        Education
                                    </Typography>
                                    <Box
                                        marginLeft="1.7rem"
                                        marginTop="1.5rem"
                                        marginBottom="1.5rem"
                                    >
                                        <PrimaryTitle variant="h6">
                                            {data?.ODSchool}
                                        </PrimaryTitle>
                                        <SecondaryTitle variant="body2">
                                            {/* School */}
                                        </SecondaryTitle>
                                    </Box>
                                    {/* <Typography variant="h6">
                                        Affiliations
                                    </Typography>
                                    <Box marginLeft="1.7rem" marginTop="1.5rem">
                                        <PrimaryTitle variant="h6">
                                            Denver Metro Optometric Society
                                            (DMOS)
                                        </PrimaryTitle>
                                    </Box> */}
                                </Box>

                                <Box marginTop="2rem">
                                    <Typography variant="h6">
                                        Locations
                                    </Typography>
                                    <MultipleBox>
                                        {Array.isArray(
                                            data?.BusinessAddress1
                                        ) ? (
                                            data?.BusinessAddress1.map(
                                                (
                                                    item: string,
                                                    index: number
                                                ) => (
                                                    <>
                                                        <GlbobalList
                                                            flex={false}
                                                            justify={false}
                                                            column
                                                        >
                                                            <AppList
                                                                isBorder={false}
                                                                isPrimaryTitle
                                                                primaryTitle={
                                                                    data
                                                                        ?.BusinessAddress1[
                                                                        index
                                                                    ]
                                                                }
                                                                isSecondaryTitle
                                                                secondaryTitle={
                                                                    null
                                                                }
                                                                isSubSecondaryTitle={
                                                                    null
                                                                }
                                                                subSecondaryTitle={
                                                                    null
                                                                }
                                                                isIcon={
                                                                    !!AddressIcon
                                                                }
                                                                Icon={
                                                                    AddressIcon
                                                                }
                                                            />
                                                            <AppList
                                                                isBorder={false}
                                                                isPrimaryTitle
                                                                primaryTitle={
                                                                    data
                                                                        ?.BusinessAddress2[
                                                                        index
                                                                    ]
                                                                }
                                                                isSecondaryTitle
                                                                secondaryTitle={
                                                                    null
                                                                }
                                                                isSubSecondaryTitle={
                                                                    null
                                                                }
                                                                subSecondaryTitle={
                                                                    null
                                                                }
                                                                isIcon={
                                                                    !!HomeIcon
                                                                }
                                                                Icon={HomeIcon}
                                                            />
                                                            <AppList
                                                                isBorder={false}
                                                                isPrimaryTitle
                                                                primaryTitle={`${data?.BusinessCity[index]},${data?.BusinessState[index]}`}
                                                                isSecondaryTitle
                                                                secondaryTitle={
                                                                    null
                                                                }
                                                                isSubSecondaryTitle={
                                                                    null
                                                                }
                                                                subSecondaryTitle={
                                                                    null
                                                                }
                                                                isIcon={
                                                                    !!LocationIcon
                                                                }
                                                                Icon={
                                                                    LocationIcon
                                                                }
                                                            />
                                                        </GlbobalList>
                                                        {index !==
                                                            data
                                                                ?.BusinessAddress1
                                                                .length -
                                                                1 && (
                                                            <Divider
                                                                variant="middle"
                                                                orientation={
                                                                    small
                                                                        ? 'horizontal'
                                                                        : 'vertical'
                                                                }
                                                                sx={{
                                                                    height: small
                                                                        ? ''
                                                                        : '140px',
                                                                    width: small
                                                                        ? '100%'
                                                                        : '',
                                                                    borderColor:
                                                                        '#007bbd',
                                                                    borderWidth: 1,
                                                                }}
                                                            />
                                                        )}
                                                    </>
                                                )
                                            )
                                        ) : (
                                            <GlbobalList
                                                flex={false}
                                                justify={false}
                                                column
                                            >
                                                <AppList
                                                    isBorder={false}
                                                    isPrimaryTitle
                                                    primaryTitle={
                                                        data?.BusinessAddress1
                                                    }
                                                    isSecondaryTitle
                                                    secondaryTitle={null}
                                                    isSubSecondaryTitle={null}
                                                    subSecondaryTitle={null}
                                                    isIcon={!!AddressIcon}
                                                    Icon={AddressIcon}
                                                />
                                                <AppList
                                                    isBorder={false}
                                                    isPrimaryTitle
                                                    primaryTitle={
                                                        data?.BusinessAddress2
                                                    }
                                                    isSecondaryTitle
                                                    secondaryTitle={null}
                                                    isSubSecondaryTitle={null}
                                                    subSecondaryTitle={null}
                                                    isIcon={!!HomeIcon}
                                                    Icon={HomeIcon}
                                                />
                                                <AppList
                                                    isBorder={false}
                                                    isPrimaryTitle
                                                    primaryTitle={`${data?.BusinessCity},${data?.BusinessState}`}
                                                    isSecondaryTitle
                                                    secondaryTitle={null}
                                                    isSubSecondaryTitle={null}
                                                    subSecondaryTitle={null}
                                                    isIcon={!!LocationIcon}
                                                    Icon={LocationIcon}
                                                />
                                            </GlbobalList>
                                        )}
                                    </MultipleBox>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </MainBox>
            )}
            {loader && !data && <Loader />}
            {error && <ToastAlert error={error} />}
        </Box>
    )
}

export default Profile
